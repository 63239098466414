import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../assets/images/about/about-img5.png'
import starIcon from '../assets/images/star-icon.png'
import icon4 from '../assets/images/icons/icon4.png'
import icon5 from '../assets/images/icons/icon5.png'
import icon6 from '../assets/images/icons/icon6.png'
import icon7 from '../assets/images/icons/icon7.png'
import shape1 from '../assets/images/shape/circle-shape1.png'

const AboutUsContent = () => {
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" />
                                    About Us
                                </span>
                                <h2>Empowering Healthcare Through Artificial Intelligence</h2>
                                <p>At AISHA (Artificial Intelligence Solutions for Healthcare Applications), we are on a mission to revolutionize the healthcare industry through the power of AI. Our name reflects our purpose: to create intelligent solutions that simplify, optimize, and transform how healthcare is delivered and managed.</p>

                                {/* <ul className="features-list">
                                    <li>
                                        <img src={icon4} alt="banner" />
                                        <h3>10 Years</h3>
                                        <p>On the market</p>
                                    </li>
                                    <li>
                                        <img src={icon5} alt="banner" />
                                        <h3>45+</h3>
                                        <p>Team members</p>
                                    </li>
                                    <li>
                                        <img src={icon6} alt="banner" />
                                        <h3>100%</h3>
                                        <p>Satisfaction rate</p>
                                    </li>
                                    <li>
                                        <img src={icon7} alt="banner" />
                                        <h3>80%</h3>
                                        <p>Senior scientist</p>
                                    </li>
                                </ul> */}
                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p> */}
                                <h2>Who We Are</h2>
                                <p>AISHA is a forward-thinking healthcare technology company focused on developing AI-driven tools that streamline medical administration, enhance operational efficiency, and improve patient outcomes. Our diverse team of engineers, clinicians, data scientists, and technology architects brings deep expertise in healthcare, artificial intelligence, and software engineering.</p>
                                <p>We understand the unique challenges faced by healthcare providers—from administrative overload and compliance complexity to fragmented data systems. Our solutions are built to bridge these gaps, freeing up valuable time and resources so providers can focus on what matters most: delivering quality care.</p>
                                {/* <Link to="/about-us" className="default-btn">
                                    <i className="flaticon-right"></i>More About Us<span></span>
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>What We Do</h3>
                                <p>Our suite of intelligent applications is designed to automate and enhance critical workflows in healthcare organizations, including:</p>

                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Medical Administration Management</li>
                                    <li><i className="flaticon-tick"></i> AI-Powered Patient Communication</li>
                                    <li><i className="flaticon-tick"></i> Smart Scheduling and Intake</li>
                                    <li><i className="flaticon-tick"></i> Claims and Documentation Automation</li>
                                    <li><i className="flaticon-tick"></i> Predictive Analytics for Operational Efficiency</li>
                                    <li><i className="flaticon-tick"></i> Custom AI Integration for Health Systems and Clinics</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our Vision</h3>
                                <p>To become the leading force in AI healthcare innovation—where intelligent automation meets compassionate care. We envision a world where administrative burdens are minimized, healthcare systems are optimized, and every patient receives the attention they deserve.</p>

                                {/* <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Creating. Results.</li>
                                    <li><i className="flaticon-tick"></i> Expect more</li>
                                    <li><i className="flaticon-tick"></i> Good thinking</li>
                                    <li><i className="flaticon-tick"></i> In real we trust</li>
                                </ul> */}
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div className="about-text">
                                <h3>Why AISHA?</h3>
                                <p>Real innovations and a positive customer experience are the heart of successful communication.</p>

                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Healthcare Expertise: We know the ins and outs of healthcare operations.</li>
                                    <li><i className="flaticon-tick"></i> AI Innovation: We harness the latest in artificial intelligence and machine learning.</li>
                                    <li><i className="flaticon-tick"></i> User-Centric Design: Our solutions are intuitive, accessible, and built for real-world use.</li>
                                    <li><i className="flaticon-tick"></i> Scalability and Security: Built for growth with compliance and privacy at the core.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>
        </section>
    )
}

export default AboutUsContent;